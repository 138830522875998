




























import Tile from './Tile.vue';
import Map from './AssetTypePage/Map.vue';

import Base from '../mixins/Base';

const Component = Base.extend({
  name: 'AssetTypePage',
  data() {
    return {
      assetType: {},
      page: {},
    };
  },
  components: {
    Tile,
    Map,
  },
  methods: {},
  created() {
    const { assetType, assetTypePage } = this.$route.params;
    const typeObj = this.wsAssetType(assetType);
    this.assetType = typeObj;
    const page = typeObj.subPages.find((p) => p.code === assetTypePage);
    if (page) {
      this.page = page;
    }
  },
});
export default Component;
