











import AssetTypeElement from './AssetTypeElement.vue';

import Base from '../mixins/Base';

const Component = Base.extend({
  name: 'AssetList',
  components: {
    AssetTypeElement,
  },
});
export default Component;
