

























// MOST logic is in Base.ts
import Base from './TileBase';

const Component = Base.extend({
  name: 'TileIcon',
  data() {
    return {
    };
  },
  methods: {
  },
});
export default Component;
