



















import Base from '../mixins/Base';

const Component = Base.extend({
  // type inference enabled
  name: 'WorkspaceList',
  data() {
    return {};
  },
  created() {
    // If we only have 1 workspace just go to that one
    const { workspaces } = this;
    if (workspaces.length === 1) {
      this.$router.push({
        name: 'Workspace',
        params: { workspace: workspaces[0] },
      });
    }
  },
});
export default Component;
