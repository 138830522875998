export function getLatestAprilFirst() {
  // get the latest April 1st date e.g. 2018 for now [04/03/2019]; 2019 for after 1st april 2019...
  const currentDate = new Date();

  // is the current month April or later in the year? if before April, use last year
  let year = null;
  if (currentDate.getMonth() >= 3) {
    year = currentDate.getFullYear();
  } else {
    year = currentDate.getFullYear() - 1;
  }

  return new Date(`01 Apr ${year.toString()}`);
}

export function thisYear(): string {
  // generates SQL to limit returned results to the current financial year
  return `created_date >= '${getLatestAprilFirst().toLocaleDateString('en-gb')}'`;
}
