import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';
import About from '../views/About.vue';
import Workspace from '../views/Workspace.vue';
import AssetTypePage from '../components/AssetTypePage.vue';

import AssetTypeSummary from '../components/AssetTypeSummary.vue';
import AssetList from '../components/AssetList.vue';
import store from '../store';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    // Login or list of workspaces
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    // About page
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    // Display asset types for this workspace
    path: '/ws/:workspace',
    component: Workspace,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        name: 'Workspace',
        path: '',
        component: AssetList,
      },
      {
        name: 'AssetTypeSummary',
        path: 'asset_type/:assetType',
        component: AssetTypeSummary,
      },
      {
        name: 'AssetTypePage',
        path: 'asset_type/:assetType/:assetTypePage',
        component: AssetTypePage,
      },
    ],
  },

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // console.log(to);
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters['auth/isAuthenticated']) {
      next({ name: 'Home' });
    } else {
      next(); // go to wherever I'm going
    }
  } else {
    next(); // does not require auth, make sure to always call next()!
  }
});

export default router;
