var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-flex",attrs:{"id":"app"}},[_c('header',{staticClass:"app-header"},[(_vm.isAuthenticated)?_c('div',{attrs:{"id":"signout"}},[_c('amplify-sign-out')],1):_vm._e(),(_vm.isAuthenticated)?_c('div',{attrs:{"id":"nav"}},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Home")]),(_vm.wsCode)?_c('router-link',{attrs:{"to":{
         name: 'Workspace',
      params: { workspace: _vm.wsCode },
    }}},[_vm._v(" | "+_vm._s(_vm._f("capitalize")(_vm.wsCode)))]):_vm._e(),(_vm.wsCode && _vm.assetType)?_c('router-link',{attrs:{"to":{
         name: 'AssetTypeSummary',
      params: { workspace: _vm.wsCode, assetType: _vm.assetType },
    }}},[_vm._v(" | "+_vm._s(_vm._f("capitalize")(_vm.assetType)))]):_vm._e(),(_vm.wsCode && _vm.assetType && _vm.assetTypePage)?_c('router-link',{attrs:{"to":{
         name: 'AssetTypePage',
      params: { workspace: _vm.wsCode, assetType: _vm.assetType, assetTypePage: _vm.assetTypePage },
    }}},[_vm._v(" | "+_vm._s(_vm._f("capitalize")(_vm.assetTypePage)))]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"clearfix"})]),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }