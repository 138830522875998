






























import Base from '../../mixins/Base';
import { MapTile } from '../../types';

const Component = Base.extend({
  name: 'AssetTypeSummary',
  props: {
    tile: {
      required: true,
    },
    assetType: {
      required: true,
    },
  },
  data() {
    return {
      iFrameSrc: '',
    };
  },

  methods: {},
  async created() {
    const mapTile = this.tile as MapTile;
    // Fetch the token!
    const url = await this.$store.dispatch('workspace/createUrlWithToken', {
      srcDomain: mapTile.srcDomain,
      srcPath: mapTile.srcPath,
      srcQuery: mapTile.srcQuery,
    });
    this.iFrameSrc = url;
  },
});
export default Component;
