import { MenuConfig } from '../../types';

const menuConfig: MenuConfig = {
  drainage: {
    tileIcon: '/img/thumbnails/Drainage.png',
    tileColor: '#66a3ff',
    title: 'Drainage',
  },
  tree: {
    tileIcon: '/img/thumbnails/Trees.png',
    tileColor: '#2d862d',
    title: 'Trees',
  },
  grass: {
    tileIcon: '/img/thumbnails/Grass1.png',
    tileColor: '#89c550',
    title: 'Grass',
  },
  grit: {
    tileIcon: '/img/thumbnails/Grit.png',
    tileColor: '#b3b300',
    title: 'Grit Bins',
  },
};

type allowedExportTypeFormat = 'CSV' | 'KML' | 'Shapefile' | 'File Geodatabase';

interface ExportTypes {
  title: string,
  exportType: allowedExportTypeFormat,
  content: string
}

const exportTypes: ExportTypes[] = [
  {
    title: 'CSV',
    exportType: 'CSV',
    content: 'Export Data as CSV',
  },
  {
    title: 'SHAPEFILE',
    exportType: 'Shapefile',
    content: 'Download Shapefile',
  },
  {
    title: 'GEODATABASE',
    exportType: 'File Geodatabase',
    content: 'Download GeoDatabase',
  },
];

export {
  menuConfig,
  exportTypes,
};
