<template>
  <main class="home app-content">
    <div v-if="isAuthenticated">
      <WorkspaceList />
    </div>
    <div v-else>
      <Login />
    </div>
  </main>
</template>

<script>

import WorkspaceList from '@/components/WorkspaceList.vue';
import Login from '@/components/Login.vue';

export default {
  name: 'Home',
  components: {
    WorkspaceList,
    Login,
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    isAuthenticated() {
      return this.$store.getters['auth/isAuthenticated'];
    },
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async created() {
    await this.$store.dispatch('workspace/clearWorkspace');
  },
};
</script>
