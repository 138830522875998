import { WSConfig } from '../../types';

import { thisYear } from '../utils';

const arcGisDomain = 'https://services-eu1.arcgis.com/';
const arcGisMapDomain = 'https://kteastlothian.maps.arcgis.com';
const featurePath = 'qBjZMtCACXOLZjf2/arcgis/rest/services/ELGritBins/FeatureServer/0';
const inspFeaturePath = 'qBjZMtCACXOLZjf2/arcgis/rest/services/BinToInspectionJoin/FeatureServer/0';

const gritColour = '#c9b500';

const config: WSConfig = {
  code: 'east-lothian',
  label: 'East Lothian',
  assetTypes: [
    {
      type: 'grit',
      motherTile: {
        title: 'Grit Bins',
        tileType: 'stat',
        tileColor: gritColour,
      },
      totalAssetsDomain: arcGisDomain,
      totalAssetsPath: `${featurePath}/query`,
      totalAssetsQuery: '&where=1=1&f=json&returnCountOnly=true',
      export: {
        tileColor: gritColour,
        srcDomain: arcGisMapDomain,
        srcPath: '/sharing/rest/content/users/KTEastLothianAdmin/export',
        itemId: '7f50331a31544f8eb3cd0ed7f6f23119',
      },
      subPages: [
        {
          code: 'map',
          motherTile: {
            tileType: 'icon',
            title: 'Map View',
            tileColor: gritColour,
            FAiconClass: 'fas fa-map-marked-alt padding-10',
          },
          tiles: [
            {
              tileType: 'iframe',
              title: 'Map',
              tileColor: gritColour,
              srcDomain: arcGisMapDomain,
              srcPath: '/apps/Embed/index.html',
              srcQuery: '&webmap=7e5a18bf884e4499b3a3b0e6b405cc3a',
            },
          ],
        },
        {
          code: 'assetData',
          motherTile: {
            tileType: 'stat',
            title: 'Asset Data',
            tileColor: gritColour,
            content: '$n Assets in Database',
            srcDomain: arcGisDomain,
            srcPath: `${featurePath}/query`,
            srcQuery: '&where=1=1&f=json&returnCountOnly=true',
          },
          tiles: [
            {
              tileType: 'stat',
              title: 'Total Assets',
              tileColor: gritColour,
              content: '$n',
              srcDomain: arcGisDomain,
              srcPath: `${featurePath}/query`,
              srcQuery: '&where=1=1&f=json&returnCountOnly=true',
            },

            {
              tileType: 'stat',
              title: 'Lid Issue',
              content: '$n',
              tileColor: gritColour,
              srcDomain: arcGisDomain,
              srcPath: `${inspFeaturePath}/query`,
              srcQuery: `&where=${encodeURIComponent(`LidCondition NOT LIKE 'OK%' AND ${thisYear()}`)}&f=json&returnCountOnly=true`,
            },
            {
              tileType: 'stat',
              title: 'Grit Level less than 50%',
              content: '$n',
              tileColor: gritColour,
              srcDomain: arcGisDomain,
              srcPath: `${inspFeaturePath}/query`,
              srcQuery: `&where=${encodeURIComponent(`GritLevel IN ('0-25%','25-50%')  AND ${thisYear()}`)}&f=json&returnCountOnly=true`,
            },
            {
              tileType: 'stat',
              title: 'Incorrectly Located',
              content: '$n',
              tileColor: gritColour,
              srcDomain: arcGisDomain,
              srcPath: `${inspFeaturePath}/query`,
              srcQuery: `&where=${encodeURIComponent(`NOT LocationStatus LIKE 'In Correct%' AND ${thisYear()}`)}&f=json&returnCountOnly=true`,
            },
          ],
          table: {
            tileType: 'table',
            title: 'Grit Bin attributes',
            tileColor: gritColour,
            tileSize: 'large',
            srcDomain: arcGisDomain,
            srcPath: `${featurePath}/query`,
            srcQuery: '&where=1=1'
                               + '&maxRecordCount=200'
                               + '&outFields=OBJECTID, PURPOSE, LOCATION_D'
                               + '&f=json',
            tableColumns: [
              { title: 'Asset ID', data: 'OBJECTID' },
              // { title: "Ward",                   data: "ward"  },
              { title: 'Purpose', data: 'PURPOSE' },
              { title: 'Location', data: 'LOCATION_D' },

            ],

          },
        },

        {
          code: 'inspectionData',
          motherTile: {
            tileType: 'stat',
            title: 'Inspection Data',
            tileColor: gritColour,
            content: '$n assets inspected this cycle',
            srcDomain: arcGisDomain,
            srcPath: `${inspFeaturePath}/query`,
            srcQuery: `&where=${encodeURIComponent(`${thisYear()}`)}&f=json&returnCountOnly=true`,
          },
          tiles: [
            {
              tileType: 'stat',
              title: 'Total Inspections',
              tileColor: gritColour,
              content: '$n',
              srcDomain: arcGisDomain,
              srcPath: `${inspFeaturePath}/query`,
              srcQuery: '&where=1=1&f=json&returnCountOnly=true',
            },

            {
              tileType: 'stat',
              title: 'Lid Issue',
              content: '$n',
              tileColor: gritColour,
              srcDomain: arcGisDomain,
              srcPath: `${inspFeaturePath}/query`,
              srcQuery: `&where=${encodeURIComponent(`LidCondition NOT LIKE 'OK%' AND ${thisYear()}`)}&f=json&returnCountOnly=true`,
            },
            {
              tileType: 'stat',
              title: 'Grit Level less than 50%',
              content: '$n',
              tileColor: gritColour,
              srcDomain: arcGisDomain,
              srcPath: `${inspFeaturePath}/query`,
              srcQuery: `&where=${encodeURIComponent(`GritLevel IN ('0-25%','25-50%')  AND ${thisYear()}`)}&f=json&returnCountOnly=true`,
            },
            {
              tileType: 'stat',
              title: 'Incorrectly Located',
              content: '$n',
              tileColor: gritColour,
              srcDomain: arcGisDomain,
              srcPath: `${inspFeaturePath}/query`,
              srcQuery: `&where=${encodeURIComponent(`NOT LocationStatus LIKE 'In Correct%' AND ${thisYear()}`)}&f=json&returnCountOnly=true`,
            },
          ],
          table: {
            tileType: 'table',
            title: 'Grit Bin attributes',
            tileColor: gritColour,
            tileSize: 'large',
            srcDomain: arcGisDomain,
            srcPath: `${inspFeaturePath}/query`,
            srcQuery: '&where=1=1'
                               + '&maxRecordCount=200'
                               + '&outFields=OBJECTID, created_date, BinOwnership, BinSize, GritLevel, LidCondition, LocationStatus'
                               + '&f=json',
            tableColumns: [
              { title: 'Asset ID', data: 'OBJECTID' },
              // { title: "Ward",                   data: "ward"  },
              { title: 'Last Inspection Date', data: 'created_date' },
              { title: 'Bin Ownership', data: 'BinOwnership' },
              { title: 'Bin Size', data: 'BinSize' },
              { title: 'Grit Level', data: 'GritLevel' },
              { title: 'Lid Condition', data: 'LidCondition' },
              { title: 'Location Status', data: 'LocationStatus' },
            ],

          },
          chart: {
            title: 'Total Inspections by Month',
            tileType: 'iframe',
            tileSize: 'large',
            tileColor: gritColour,
            iframeURL: 'https://kteastlothian.maps.arcgis.com/apps/dashboards/2fec00b6f0464b5e89067e3d1c468176',
          },
        },

      ],
    },

  ],
};

export default config;
