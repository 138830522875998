/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { API } from 'aws-amplify';

import axios from 'axios';

import { WSConfig, ConfigAsset, ExportTile } from '../types';

// Some dynamic way of loading might be nice.. but we are just making it work for now!
import walsall from './config/walsall';
import eastLothian from './config/east-lothian';

import { menuConfig, exportTypes } from './config/shared';

// When waiting for exports...
const retryTime = 5000; // ms between checks
const maxMins = 5; // how many mins to wait for
const maxExportAttempts = Number((maxMins * 60 * 1000) / retryTime).toFixed(0) + 1;

type stringOrUndef = string | undefined;

interface State {
  wsCode: stringOrUndef;
  label: stringOrUndef;
  config: WSConfig | undefined;
  apiTokens: any;
  totalAssets: number;
}

interface FetchArcGisData {
  srcDomain: string;
  srcPath: string;
  srcQuery?: string;
}
interface wsData {
  [key: string]: WSConfig
}

interface SetToken {
  token: string;
  expires: number;
  serverURL: string;
}

interface ExportJobData {
  response: string,
  statusCheckCount: number,
  exportItemId: string,
  jobId: string,
}

const workspaceData: wsData = {
  walsall,
  'east-lothian': eastLothian,
};

const model = {
  namespaced: true,
  state: {
    wsCode: undefined,
    label: undefined,
    config: undefined,
    apiTokens: {},
    totalAssets: 0,
  },
  getters: {
    wsCode: (state: State): stringOrUndef => state.wsCode,
    wsConfig: (state: State): WSConfig | undefined => state.config,
    assetTypes: (state: State): ConfigAsset[] => state.config?.assetTypes || [],
    assetType: (state: State) => (type: string) => {
      const assets = state.config?.assetTypes || [];
      return assets.find((record) => record.type === type);
    },
  },
  mutations: {
    setWorkspace(state: State, workspace: WSConfig): void {
      state.wsCode = workspace.code;
      state.label = workspace.label;
      state.config = workspace;
    },
    unSetWorkspace(state: State): void {
      state.wsCode = undefined;
      state.label = undefined;
      state.config = undefined;
      state.apiTokens = {};
    },
    setToken(state: State, tokenData: SetToken): void {
      state.apiTokens[tokenData.serverURL] = tokenData;
    },
    setWsCode(state: State, code: string): void {
      state.wsCode = code;
    },

  },
  actions: {

    async clearWorkspace(ctx: any): Promise<void> {
      ctx.commit('unSetWorkspace');
    },

    // Called to load a workspace, also triggers fetching new token
    async loadWorkspace(ctx: any, workspace: string): Promise<void> {
      // This could actually be an API call or something if needed
      const ws = workspaceData[workspace];

      // Set this early on - so available for token querying
      await ctx.commit('setWsCode', ws.code);

      ws.assetTypes.forEach((assetType) => {
        if (menuConfig[assetType.type]) {
          // eslint-disable-next-line no-param-reassign
          assetType.menuConfig = menuConfig[assetType.type];
          // eslint-disable-next-line no-param-reassign
          assetType.total = 0; // default so there is a bound value
          // Fetch the totals
          ctx.dispatch('fetchArcGisData', {
            srcDomain: assetType.totalAssetsDomain,
            srcPath: assetType.totalAssetsPath,
            srcQuery: assetType.totalAssetsQuery,
            code: ws.code,
          }).then((response: any) => {
            // eslint-disable-next-line no-param-reassign
            assetType.total = response.count;
          });

          if (assetType.export) {
            const exportConf = assetType.export;
            const exportTiles: ExportTile[] = [];
            exportTypes.forEach((exportType) => {
              exportTiles.push({
                title: exportType.title,
                exportType: exportType.exportType,
                tileType: 'export',
                tileColor: exportConf.tileColor,
                itemId: exportConf.itemId,
                srcDomain: exportConf.srcDomain,
                srcPath: exportConf.srcPath,
                status: 'na',
              });
            });
            // eslint-disable-next-line no-param-reassign
            assetType.exportTiles = exportTiles;
          }
        }
      });
      ctx.commit('setWorkspace', ws);
    },

    // Internal method for accessing a token and fetching a new
    // one if needed
    async tokenForServerUrl(ctx: any, serverURL: string): Promise<stringOrUndef> {
      // See if we have one for this Url
      const existingToken = ctx.state.apiTokens[serverURL];

      // Check if it's expired
      if (existingToken) {
        const { wsCode } = ctx.state;
        if (!wsCode) {
          throw new Error('Calling tokenForServerUrl() before wsCode is set');
        }
        const { expires } = existingToken;
        const now = new Date();
        if (now.getTime() > (expires || 0)) {
          console.log('Token expired - fetching a new one');
          await ctx.dispatch('internalFetchToken', serverURL);
        }
      } else {
        console.log(`Token not in cache [${serverURL}] - fetching a new one`);
        await ctx.dispatch('internalFetchToken', serverURL);
      }

      // At this point we should have a token
      return ctx.state.apiTokens[serverURL]?.token;
    },

    async internalFetchToken(ctx: any, serverURL: string) {
      const { wsCode } = ctx.state;
      const apiName = 'token';
      const path = `/token/${wsCode}`;
      const myInit = {
        queryStringParameters: {
          serverURL,
        },
        headers: {},
      };

      try {
        const response = await API.get(apiName, path, myInit);
        response.serverURL = serverURL;
        await ctx.commit('setToken', response);
      } catch (error) {
        console.warn(error);
      }
    },

    async createUrlWithToken(ctx: any, args: FetchArcGisData): Promise<string> {
      // fetch the token for this domain
      const token = await ctx.dispatch('tokenForServerUrl', args.srcDomain);
      // Build URL
      return `${args.srcDomain + args.srcPath}?token=${token}${args.srcQuery || ''}`;
    },

    async fetchArcGisData(ctx: any, args: FetchArcGisData): Promise<any> {
      const url = await ctx.dispatch('createUrlWithToken', args);
      let data: any;
      try {
        const response = await axios.get(url);
        data = response.data;
      } catch (error) {
        console.warn(error);
      }
      return data;
    },

    async checkExport(ctx: any, args: any) {
      const { jobData, tile } = args;

      const srcPath = `${tile.srcPath}/items/${jobData.exportItemId}/status`;

      const token = await ctx.dispatch('tokenForServerUrl', tile.srcDomain);

      const body = {
        token,
        jobID: jobData.jobId,
        jobType: 'export',
        f: 'json',
        url: tile.srcDomain + srcPath,
      };

      const apiName = 'token';
      const path = '/export/start';

      const myInit = {
        body,
        headers: {},
      };

      try {
        jobData.statusCheckCount += 1;

        const jobStatusData = await API.post(apiName, path, myInit);
        if (jobStatusData?.status === 'completed') {
          console.log(JSON.parse(JSON.stringify(jobData)));

          const downloadUrl = `https://kaarbon-tech.maps.arcgis.com/sharing/rest/content/items/${jobData.exportItemId}/data?token=${token}`;

          // We need the noreferrer so tokens match
          window.open(downloadUrl, '_blank', 'noopener,noreferrer');

          tile.status = 'completed';
        } else if (jobData.statusCheckCount >= maxExportAttempts) {
          tile.status = 'failed';
        } else {
          // Try again shortly!
          setTimeout(() => {
            ctx.dispatch('checkExport', args);
          }, retryTime);
        }
      } catch (error) {
        tile.status = 'failed';
        console.warn(error);
      }
    },

    async exportData(ctx: any, tile: ExportTile): Promise<any> {
      // https://developers.arcgis.com/rest/users-groups-and-items/export-item.htm ?
      // https://github.com/axios/axios#using-applicationx-www-form-urlencoded-format

      // eslint-disable-next-line no-param-reassign
      tile.status = 'generating';

      // Maybe this should be in the workspace config?
      const j = {
        layers: [{ id: 0 }],
        // enforceFieldVisibility: true,
        targetSR: 'EPSG:27700',
      };

      const exportToken = await ctx.dispatch('tokenForServerUrl', tile.srcDomain);

      const body = {
        exportFormat: tile.exportType,
        itemId: tile.itemId,
        // title: 'KTexport', // Could come from the tile.title ?
        exportParameters: j,
        token: exportToken,
        f: 'json',
        url: `${tile.srcDomain + tile.srcPath}/export`,
      };

      const apiName = 'token';
      const path = '/export/start';

      const myInit = {
        body,
        headers: {},
      };

      try {
        const jobData = await API.post(apiName, path, myInit);
        jobData.statusCheckCount = 0;

        // Now start checking if we have any response - async
        ctx.dispatch('checkExport', { jobData, tile });
      } catch (error) {
        console.warn(error);
        // Reset
        // eslint-disable-next-line no-param-reassign
        tile.status = 'failed';
      }
    },

  },
};

export default model;
