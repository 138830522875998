
































import Base from '../mixins/Base';
import { ConfigAsset } from '../types';

const Component = Base.extend({
  name: 'AssetTypeElement',
  props: {
    assetType: {
      required: true,
    },
  },
  computed: {
    haveAssets(): boolean {
      const assetType = this.assetType as ConfigAsset;
      return !!assetType.total;
    },
  },
});
export default Component;
