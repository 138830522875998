import '@aws-amplify/ui-vue';
import Amplify from 'aws-amplify';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// eslint-disable-next-line
const awsExports = require('./aws-exports.js').default;

Amplify.configure(awsExports);
applyPolyfills().then(() => {
  defineCustomElements(window);
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
