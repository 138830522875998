







































































// MOST code is in TileBase.ts
import Base from './Tiles/TileBase';

import TileIcon from './Tiles/TileIcon.vue';
import TileExport from './Tiles/TileExport.vue';
import TileIframe from './Tiles/TileIframe.vue';
import TileTable from './Tiles/TileTable.vue';

const Component = Base.extend({
  name: 'Tile',
  components: {
    TileIcon,
    TileExport,
    TileIframe,
    TileTable,
  },
});
export default Component;
