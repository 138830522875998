/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Auth } from 'aws-amplify';
import { AWSUser } from '../types';

interface State {
  isAuthenticated: boolean;
  user: AWSUser | null;
  workspaces: string[]
}

const model = {
  namespaced: true,
  state: {
    isAuthenticated: false,
    user: null,
    workspaces: [],
  },
  getters: {
    isAuthenticated: (state: State): boolean => state.isAuthenticated,
    user: (state: State): AWSUser | null => state.user,
    workspaces: (state: State): string[] => state.workspaces,
  },
  mutations: {
    setUser(state: State, user: AWSUser): void {
      state.isAuthenticated = true;
      state.user = user;
      state.workspaces = user.signInUserSession.accessToken.payload['cognito:groups'];
    },
    unsetUser(state: State): void {
      state.isAuthenticated = false;
      state.user = null;
      state.workspaces = [];
    },
  },
  actions: {
    async getCurrentUser(ctx: any): Promise<void> {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        ctx.commit('setUser', currentUser);
      } catch {
        ctx.commit('unsetUser');
      }
    },
    async signOut(ctx: any): Promise<void> {
      ctx.commit('unsetUser');
    },

  },

};

export default model;
