import { WSConfig } from '../../types';

import { thisYear } from '../utils';

const arcGisDomain = 'https://services9.arcgis.com/';
const arcGisMapDomain = 'https://kaarbon-tech.maps.arcgis.com';
const featurePath = '/2wWVtGr9qjkX6IT7/ArcGIS/rest/services/WalsallHistoricNodeDataCaptureUPDATEFeb2020_BNG_ViewLayer/FeatureServer';
const inspFeaturePath = '/2wWVtGr9qjkX6IT7/ArcGIS/rest/services/WalsallHistoricNodeDataCaptureUPDATEFeb2020_BNG_ViewLayer/FeatureServer';

const drainColor = '#66a3ff';
const treeColor = '#2d862d';

const config: WSConfig = {
  code: 'walsall',
  label: 'Walsall',
  assetTypes: [
    {
      type: 'drainage',
      motherTile: {
        title: 'Drainage',
        tileType: 'stat',
        tileColor: drainColor,
      },
      totalAssetsDomain: arcGisDomain,
      totalAssetsPath: `${featurePath}/0/query`,
      totalAssetsQuery: '&where=1=1&f=json&returnCountOnly=true',
      export: {
        tileColor: drainColor,
        srcDomain: arcGisMapDomain,
        srcPath: '/sharing/rest/content/users/KaarbonApps',
        itemId: '5240ac8096834d249b977e0109268c8c',
        //        itemId: '7f50331a31544f8eb3cd0ed7f6f23119',
      },
      subPages: [
        {
          code: 'map',
          motherTile: {
            tileType: 'icon',
            title: 'Map View',
            tileColor: drainColor,
            FAiconClass: 'fas fa-map-marked-alt padding-10',
          },
          tiles: [
            {
              tileType: 'iframe',
              title: 'Map',
              tileColor: drainColor,
              srcDomain: arcGisMapDomain,
              srcPath: '/apps/webappviewer/index.html',
              srcQuery: '&id=17725bc073734b96aa102138a6755e11',
            },
          ],
        },
        {
          code: 'assetData',
          motherTile: {
            tileType: 'stat',
            title: 'Asset Data',
            tileColor: drainColor,
            content: '$n Assets in Database',
            srcDomain: arcGisDomain,
            srcPath: `${featurePath}/0/query`,
            srcQuery: '&where=1=1&f=json&returnCountOnly=true',
          },
          tiles: [
            {
              tileType: 'stat',
              title: 'Total Assets',
              tileColor: drainColor,
              content: '$n',
              srcDomain: arcGisDomain,
              srcPath: `${featurePath}/0/query`,
              srcQuery: '&where=1=1&f=json&returnCountOnly=true',
            },
            {
              tileType: 'statPercentage',
              title: 'Cleaned %',
              tileColor: drainColor,
              content: '$n',
              srcDomain: arcGisDomain,
              srcPath: `${featurePath}/1/query`,
              srcQuery: `&where=${encodeURIComponent(`Cleaned LIKE 'Cleaned%' AND ${thisYear()}`)}&f=json&returnCountOnly=true`,
            },

            {
              tileType: 'stat',
              title: 'Inaccessible',
              content: '$n',
              tileColor: drainColor,
              srcDomain: arcGisDomain,
              srcPath: `${featurePath}/1/query`,
              srcQuery: `&where=${encodeURIComponent(`Accessible NOT LIKE 'OK%' AND ${thisYear()}`)}&f=json&returnCountOnly=true`,
            },
            {
              tileType: 'stat',
              title: 'Cover Defects',
              content: '$n',
              tileColor: drainColor,
              srcDomain: arcGisDomain,
              srcPath: `${featurePath}/1/query`,
              srcQuery: `&where=${encodeURIComponent(`CoverCond NOT LIKE 'OK%' AND ${thisYear()}`)}&f=json&returnCountOnly=true`,
            },
            {
              tileType: 'stat',
              title: 'Silt Over 75%',
              content: '$n',
              tileColor: drainColor,
              srcDomain: arcGisDomain,
              srcPath: `${featurePath}/1/query`,
              srcQuery: `&where=${encodeURIComponent(`(SiltLevel='75' OR SiltLevel='100') AND ${thisYear()}`)}&f=json&returnCountOnly=true`,
            },
            {
              tileType: 'stat',
              title: 'Not Operational',
              content: '$n',
              tileColor: drainColor,
              srcDomain: arcGisDomain,
              srcPath: `${featurePath}/1/query`,
              srcQuery: `&where=${encodeURIComponent(`OperationalLeaving = 'No' AND ${thisYear()}`)}&f=json&returnCountOnly=true`,
            },
          ],
          table: {
            tileType: 'table',
            title: 'Drainage attributes',
            tileColor: drainColor,
            tileSize: 'large',
            srcDomain: arcGisDomain,
            srcPath: `${featurePath}/1/query`,
            srcQuery: '&where=1=1'
                               + '&maxRecordCount=200'
                               + '&outFields=OBJECTID, created_date, Cleaned, SiltLevel, SiltDefect, CoverCond, OperationalLeaving'
                               + '&f=json',
            tableColumns: [
              { title: 'Asset ID', data: 'OBJECTID' },
              // { title: "Ward",                   data: "ward"  },
              { title: 'Last Inspection Date', data: 'created_date' },
              { title: 'Cleaned', data: 'Cleaned' },
              { title: 'Silt Level', data: 'SiltLevel' },
              { title: 'Silt Defect', data: 'SiltDefect' },
              { title: 'Cover Condition', data: 'CoverCond' },
              { title: 'Operational on leaving', data: 'OperationalLeaving' },
            ],

          },
        },

        {
          code: 'inspectionData',
          motherTile: {
            tileType: 'stat',
            title: 'Inspection Data',
            tileColor: drainColor,
            content: '$n assets cleaned this cycle',
            srcDomain: arcGisDomain,
            srcPath: `${featurePath}/1/query`,
            srcQuery: `&where=${encodeURIComponent(`Cleaned LIKE 'Cleaned%' AND ${thisYear()}`)}&f=json&returnCountOnly=true`,
          },
          tiles: [
            {
              tileType: 'stat',
              title: 'Total Assets',
              tileColor: drainColor,
              content: '$n',
              srcDomain: arcGisDomain,
              srcPath: `${featurePath}/1/query`,
              srcQuery: '&where=1=1&f=json&returnCountOnly=true',
            },
            {
              tileType: 'statPercentage',
              title: 'Cleaned %',
              tileColor: drainColor,
              content: '$n',
              srcDomain: arcGisDomain,
              srcPath: `${featurePath}/1/query`,
              srcQuery: `&where=${encodeURIComponent(`Cleaned LIKE 'Yes%' AND ${thisYear()}`)}&f=json&returnCountOnly=true`,
            },
            {
              tileType: 'stat',
              title: 'Inaccessible',
              content: '$n',
              tileColor: drainColor,
              srcDomain: arcGisDomain,
              srcPath: `${featurePath}/1/query`,
              srcQuery: `&where=${encodeURIComponent("Accessible NOT LIKE 'OK%'")}&f=json&returnCountOnly=true`,
            },
            {
              tileType: 'stat',
              title: 'Not Operational',
              content: '$n',
              tileColor: drainColor,
              srcDomain: arcGisDomain,
              srcPath: `${featurePath}/1/query`,
              srcQuery: `&where=${encodeURIComponent(`OperationalLeaving = 'No' AND ${thisYear()}`)}&f=json&returnCountOnly=true`,
            },
          ],
          chart: {
            title: 'Total Inspections by Month',
            tileType: 'iframe',
            tileColor: drainColor,
            tileSize: 'large',
            iframeURL: 'https://www.arcgis.com/apps/opsdashboard/index.html#/da5af44315894f0faa08d545d337f902',
          },
        },
        {
          code: 'kpi',
          motherTile: {
            tileType: 'icon',
            title: 'KPI View',
            tileColor: drainColor,
            FAiconClass: 'fas fa-clipboard-list padding-10',
          },
          tiles: [
            {
              tileType: 'iframe',
              title: 'Cleaned',
              tileColor: drainColor,
              tileSize: 'large',
              iframeURL: 'https://www.arcgis.com/apps/opsdashboard/index.html#/29ccfff3ef2f44af9221b4080d908def',
            },
            {
              tileType: 'iframe',
              title: 'Silt Level',
              tileColor: drainColor,
              tileSize: 'large',
              iframeURL: 'https://www.arcgis.com/apps/opsdashboard/index.html#/3326105b678845299d2a7ee7bc3e7af5',
            },
            {
              tileType: 'iframe',
              title: 'Cover Condition',
              tileColor: drainColor,
              tileSize: 'large',
              iframeURL: 'https://www.arcgis.com/apps/opsdashboard/index.html#/eeb2abebdb3d45bbb7eecfe766637289',
            },
            {
              tileType: 'iframe',
              title: 'Access',
              tileColor: drainColor,
              tileSize: 'large',
              iframeURL: 'https://www.arcgis.com/apps/opsdashboard/index.html#/9d07d1c8e7cb421eb8bd1afe932e2a31',
            },
          ],
        },
      ],
    },
    {
      type: 'tree',
      motherTile: {
        title: 'Trees',
        tileType: 'stat',
        tileColor: treeColor,
      },
      totalAssetsDomain: arcGisDomain,
      totalAssetsPath: '/2wWVtGr9qjkX6IT7/arcgis/rest/services/Walsall_TreesDataCapture/FeatureServer/0/query',
      totalAssetsQuery: '&where=1=1&f=json&returnCountOnly=true',
      subPages: [
        {
          code: 'map',
          motherTile: {
            tileType: 'icon',
            title: 'Map View',
            tileColor: treeColor,
            FAiconClass: 'fas fa-map-marked-alt padding-10',
          },
          tiles: [
            {

              tileType: 'iframe',
              title: 'Map view',
              tileColor: treeColor,
              srcDomain: arcGisMapDomain,
              srcPath: '/apps/webappviewer/index.html',
              srcQuery: '&id=2a279b8042f7435bbd679907bad745f9',
            },
          ],

        },
        {
          code: 'kpi',
          motherTile: {
            tileType: 'icon',
            title: 'KPI View',
            tileColor: treeColor,
            FAiconClass: 'fas fa-clipboard-list padding-10',
          },
          tiles: [
            {
              tileType: 'iframe',
              title: 'Vitality',
              tileColor: treeColor,
              tileSize: 'medium',
              iframeURL: 'https://www.arcgis.com/apps/opsdashboard/index.html#/4e0a2040bcbf4e37830c10f9d4ac8197',
            },
            {
              tileType: 'iframe',
              title: 'Life Stage',
              tileColor: treeColor,
              tileSize: 'medium',
              iframeURL: 'https://www.arcgis.com/apps/opsdashboard/index.html#/bfb518ec797b438ea375842386e3a6d8',
            },
            {
              tileType: 'iframe',
              title: 'Remaining Contribution',
              tileColor: treeColor,
              tileSize: 'medium',
              iframeURL: 'https://www.arcgis.com/apps/opsdashboard/index.html#/d594558f9eb5433eb1d0bb837f566395',
            },
            {
              tileType: 'iframe',
              title: 'Species',
              tileColor: treeColor,
              tileSize: 'medium',
              iframeURL: 'https://www.arcgis.com/apps/opsdashboard/index.html#/400b492d6d0b4a1ea083c2ddfa267d61',
            },
          ],
        },
        {
          code: 'assetData',
          motherTile: {
            tileType: 'stat',
            title: 'Asset Data',
            tileColor: treeColor,
            content: '$n Assets in Database',
            srcDomain: arcGisDomain,
            srcPath: '/2wWVtGr9qjkX6IT7/arcgis/rest/services/Walsall_TreesDataCapture/FeatureServer/0/query',
            srcQuery: '&where=1=1&f=json&returnCountOnly=true',
          },
          tiles: [
            {
              tileType: 'stat',
              title: 'Total Assets',
              tileColor: treeColor,
              content: '$n',
              srcDomain: arcGisDomain,
              srcPath: `${featurePath}/0/query`,
              srcQuery: '&where=1=1&f=json&returnCountOnly=true',
            },
            {
              tileType: 'statPercentage',
              title: 'Cleaned %',
              tileColor: treeColor,
              content: '$n',
              srcDomain: arcGisDomain,
              srcPath: `${featurePath}/1/query`,
              srcQuery: `&where=${encodeURIComponent(`Cleaned LIKE 'Cleaned%' AND ${thisYear()}`)}&f=json&returnCountOnly=true`,
            },
            {
              tileType: 'stat',
              title: 'Inaccessible',
              content: '$n',
              tileColor: treeColor,
              srcDomain: arcGisDomain,
              srcPath: `${featurePath}/1/query`,
              srcQuery: `&where=${encodeURIComponent(`Accessible NOT LIKE 'OK%' AND ${thisYear()}`)}&f=json&returnCountOnly=true`,
            },
            {
              tileType: 'stat',
              title: 'Cover Defects',
              content: '$n',
              tileColor: treeColor,
              srcDomain: arcGisDomain,
              srcPath: `${featurePath}/1/query`,
              srcQuery: `&where=${encodeURIComponent(`CoverCond NOT LIKE 'OK%' AND ${thisYear()}`)}&f=json&returnCountOnly=true`,
            },
            {
              tileType: 'stat',
              title: 'Silt Over 75%',
              content: '$n',
              tileColor: treeColor,
              srcDomain: arcGisDomain,
              srcPath: `${featurePath}/1/query`,
              srcQuery: `&where=${encodeURIComponent(`(SiltLevel='75' OR SiltLevel='100') AND ${thisYear()}`)}&f=json&returnCountOnly=true`,
            },
            {
              tileType: 'stat',
              title: 'Not Operational',
              content: '$n',
              tileColor: treeColor,
              srcDomain: arcGisDomain,
              srcPath: `${featurePath}/1/query`,
              srcQuery: `&where=${encodeURIComponent(`OperationalLeaving = 'No' AND ${thisYear()}`)}&f=json&returnCountOnly=true`,
            },

          ],
          table: {
            tileType: 'table',
            title: 'Drainage attributes',
            tileColor: treeColor,
            tileSize: 'large',
            srcDomain: arcGisDomain,
            srcPath: `${inspFeaturePath}/0/query`,
            srcQuery: `&where=${encodeURIComponent('ObjectId < 1000')
            }&outFields=NodeRef,ward,created_date_1550400990492,Cleaned,SiltLevel,SiltDefect,CoverCond,OperationalLeaving`
                     + '&f=json',
            tableColumns: [
              { title: 'Asset ID', data: 'OBJECTID' },
              // { title: "Ward",                   data: "ward"  },
              { title: 'Last Inspection Date', data: 'created_date' },
              { title: 'Cleaned', data: 'Cleaned' },
              { title: 'Silt Level', data: 'SiltLevel' },
              { title: 'Silt Defect', data: 'SiltDefect' },
              { title: 'Cover Condition', data: 'CoverCond' },
              { title: 'Operational on leaving', data: 'OperationalLeaving' },
            ],
          },
        },
      ],
      export: {
        tileColor: treeColor,
        srcDomain: 'https://kaarbon-tech.maps.arcgis.com',
        srcPath: '/sharing/rest/content/users/KaarbonApps/export',
        itemId: '6e8d3ad0c377419b825070d640ff9943',
      },
    },
  ],
};

export default config;
