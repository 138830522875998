import Vue from 'vue';
import { Tile, ConfigAsset, ExportTile } from '../../types';

const tileSizeToClasses = {
  small: 'tile tileS',
  medium: 'tile tileM',
  large: 'tile tileL',
};

const Component = Vue.extend({
  name: 'Tile',
  props: {
    tile: {
      required: true,
    },
    assetType: {
      required: true,
    },
    linkCode: {
      default: undefined,
    },
  },
  data() {
    return {
      content: '',
      tableData: [],
      data: undefined,
      cursor: 'pointer',
      tileClass: tileSizeToClasses.small, // default
    };
  },
  methods: {
    async clickTile() {
      const tile = this.tile as Tile;

      if (this.linkCode) {
        // link into the page
        this.$router.push({
          name: 'AssetTypePage',
          params: this.linkCode,
        });
      } else if (tile.tileType === 'export') {
        // FIXME: do what ever logic is needed to perform and export of the
        const exportTile = tile as ExportTile;
        await this.$store.dispatch('workspace/exportData', exportTile);
      }
    },
  },
  async created() {
    const tile = this.tile as Tile;
    const assetType = this.assetType as ConfigAsset;

    // Sort out classes if the tile size is set
    if (tile.tileSize) {
      this.tileClass = tileSizeToClasses[tile.tileSize];
    }

    // Fetch data if there is any
    if (tile.srcDomain && tile.tileType !== 'export') {
      // Need to fetch data... go do it
      const data = await this.$store.dispatch('workspace/fetchArcGisData', {
        srcDomain: tile.srcDomain,
        srcPath: tile.srcPath,
        srcQuery: tile.srcQuery,
      });
      console.log(tile.title, data);

      const { count, features } = data;

      if (tile.tileType === 'statPercentage') {
        const percent = (count / (assetType.total || 1)) * 100;
        this.content = tile.content?.replace(/\$n/, percent.toString())
          || 'Unable to calculate';
      } else if (tile.tileType === 'table') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.tableData = features.map((x: any) => x.attributes);
      } else {
        this.content = tile.content?.replace(/\$n/, count) || 'Unable to calculate';
      }
    } else {
      this.content = tile.content || '';
    }
    // This is NOT a link
    if (!this.linkCode && tile.tileType !== 'export') {
      // No mouse over hand
      this.cursor = 'unset';
    }
  },
});
export default Component;
