















import Tile from './Tile.vue';
import { ConfigSubPage } from '../types';

import Base from '../mixins/Base';

const Component = Base.extend({
  name: 'AssetTypeSummary',
  data() {
    return {
      assetType: {},
      assetTypeCode: '',
      exports: [],
    };
  },
  components: {
    Tile,
  },
  methods: {
    linkCode(subPage: ConfigSubPage) {
      return {
        workspace: this.wsCode,
        assetType: this.assetTypeCode,
        assetTypePage: subPage.code,
      };
    },
  },
  created() {
    this.assetTypeCode = this.$route.params.assetType;
    const assetType = this.wsAssetType(this.assetTypeCode);
    this.assetType = assetType;
  },

});
export default Component;
