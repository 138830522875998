













































import { Hub } from 'aws-amplify';

import Base from './mixins/Base';

const Component = Base.extend({
  // type inference enabled
  name: 'AppWrapper',
  data() {
    return {
      assetType: '',
      assetTypePage: '',
    };
  },
  created() {
    // When we load see if there is a current user
    this.$store.dispatch('auth/getCurrentUser', {
      root: true,
    });
  },
  updated() {
    this.assetType = this.$route.params.assetType;
    this.assetTypePage = this.$route.params.assetTypePage;
  },
  beforeCreate() {
    // Setup listeners to signIn / signOut events
    Hub.listen('auth', (data) => {
      const { payload } = data;
      if (payload.event === 'signIn') {
        this.$store.dispatch('auth/getCurrentUser');
      }
      if (payload.event === 'signOut') {
        // clear everything out
        this.$store.dispatch('auth/signOut');
        // Send back to the home page
        this.$router.push({ name: 'Home' });
      }
    });
  },
  filters: {
    capitalize(value: string) {
      if (!value) return '';
      // eslint-disable-next-line no-param-reassign
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
});
export default Component;
