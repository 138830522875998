// See https://stackoverflow.com/questions/59239650/how-to-fix-linter-issues-with-mixins-in-vue-typescript

import Vue from 'vue';

import { ConfigAsset, AWSUser } from '../types';

interface wsList {
  code: string;
  label: string;
}

const Component = Vue.extend({
  computed: {
    isAuthenticated(): boolean {
      return this.$store.getters['auth/isAuthenticated'];
    },
    user(): AWSUser {
      return this.$store.getters['auth/user'];
    },
    workspaces(): string[] {
      return this.$store.getters['auth/workspaces'];
    },
    workspacesFormatted(): wsList[] {
      const workspaces = this.$store.getters['auth/workspaces'];
      const out: wsList[] = [];
      workspaces.forEach((ws: string) => {
        const label = ws.replace(/-/g, ' ');
        out.push({
          code: ws,
          label: label.charAt(0).toUpperCase() + label.slice(1),
        });
      });
      return out;
    },
    wsCode() {
      return this.$store.getters['workspace/wsCode'];
    },
    wsConfig() {
      return this.$store.getters['workspace/wsCode'];
    },
    wsAssetTypes(): ConfigAsset[] {
      return this.$store.getters['workspace/assetTypes'] as ConfigAsset[];
    },
  },
  methods: {
    wsAssetType(assetType: string): ConfigAsset {
      return this.$store.getters['workspace/assetType'](assetType) as ConfigAsset;
    },
  },
});
export default Component;
