










import Base from '../mixins/Base';

const Component = Base.extend({
  name: 'Workspace',
  components: {
  },
  async created() {
    const { workspace } = this.$route.params;

    // When we load see if there is a current user
    await this.$store.dispatch('workspace/loadWorkspace', workspace);
  },
});
export default Component;
